import {Form} from 'react-bootstrap'
import RowSpacer from '../../../../common/styles/RowSpacer';
import AddButton from '../../../../common/button/AddButton';
import {BooksitoutServer} from '../../../../config/BooksitoutServer';
import {useParams} from 'react-router-dom';
import React, {useState} from 'react';
import toast from 'react-hot-toast';
import Modal from '../../../../common/Modal';
import TextForm from "../../../../common/form/TextForm";
import BookMemoResponse from "./BookMemoResponse";
import useBookDetailStore from "../useBookDetailStore";
import NumberInput from "../../../../common/form/NumberInput";

interface Props {
    isOpen: boolean
    close: () => void
}

const BookDetailMemoAddModal: React.FC<Props> = ({isOpen, close}) => {
    const {bookId} = useParams()
    const {book} = useBookDetailStore()
    const {addMemo} = useBookDetailStore()

    const [page, setPage] = useState<number | null>(null)
    const [content, setContent] = useState<string>('')

    const addMemoToServer = () => {
        if (content === '') {
            toast.error('메모 내용을 입력해주세요.')
            return
        }

        const data = {
            page: page === 0 ? null : page,
            content: content
        }

        BooksitoutServer
            .post(`/v1/book/${bookId}/memo`, data)
            .then((res) => {
                toast.success('메모를 추가했어요.')
                addMemo(res.data as BookMemoResponse)
                close()
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요.'))
    }

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        addMemoToServer()
    }

    return (
        <Modal
            isShowing={isOpen}
            onClose={close}
            titleText={'✏️ 메모 추가하기'}
            size={'lg'}
            isPreventClose={true}
            body={
                <Form onSubmit={handleOnSubmit}>
                    <Form.Group>
                        <NumberInput
                            placeholder="페이지 번호"
                            min={1}
                            max={book?.endPage ?? 10_000}
                            onChange={(e) => setPage(Number(e.target.value))}
                        />
                    </Form.Group>

                    <RowSpacer size={10}/>

                    <Form.Group>
                        <TextForm placeholder={'메모 내용'} setContent={setContent} height={300}/>
                    </Form.Group>

                    <RowSpacer size={10}/>

                    <AddButton label={'추가하기'} onClick={addMemoToServer}/>
                </Form>
            }/>
    )
}

export default BookDetailMemoAddModal