import {create} from "zustand";
import BookMemoResponse from "./memo/BookMemoResponse";
import BookReadingSessionResponse from "../reading/BookReadingSessionResponse";
import BookDetailResponse from "./BookDetailResponse";
import BookResponse from "../BookResponse";
import BookStatus from "./BookStatusType";

interface BookDetailStore {
    book: BookResponse | null
    memos: BookMemoResponse[]
    readingSessions: BookReadingSessionResponse[]

    addMemo: (memo: BookMemoResponse) => void
    editMemo: (memo: BookMemoResponse) => void
    deleteMemo: (memoId: number) => void

    detailMemo: BookMemoResponse | null
    openDetailMemo: (memo: BookMemoResponse) => void
    closeDetailMemo: () => void

    addReadingSession: (readingSession: BookReadingSessionResponse) => void
    editReadingSession: (readingSession: BookReadingSessionResponse) => void
    deleteReadingSession: (readingSessionId: number) => void

    updateBookStatus: (status: BookStatus) => void

    init: (book: BookDetailResponse) => void
}

const useBookDetailStore = create<BookDetailStore>(set => ({
    book: null,
    memos: [],
    readingSessions: [],

    addMemo: (memo) => set(state => ({memos: [...state.memos, memo]})),
    editMemo: (memo) => set(state => (
        {
            memos: state.memos.map(m => m.id === memo.id ? memo : m),
            detailMemo: memo
        }
    )),
    deleteMemo: (memoId) => set(state => ({memos: state.memos.filter(memo => memo.id !== memoId)})),

    detailMemo: null,
    openDetailMemo: (memo) => set({detailMemo: memo}),
    closeDetailMemo: () => set({detailMemo: null}),

    addReadingSession: (readingSession) => set(state => ({
        readingSessions: [...state.readingSessions, readingSession],
        book: state.book
            ? {
                ...state.book,
                currentPage: Math.max(readingSession.endPage ?? 0, state.book.currentPage),
            }
            : null
    })),
    editReadingSession: (readingSession) => set(state => ({readingSessions: state.readingSessions.map(rs => rs.id === readingSession.id ? readingSession : rs)})),
    deleteReadingSession: (readingSessionId) => set(state => ({readingSessions: state.readingSessions.filter(readingSession => readingSession.id !== readingSessionId)})),

    updateBookStatus: (status) => set(state => ({
        book: state.book ? {...state.book, status: status} : state.book
    })),

    init: (book) => {
        set({book: book.book})
        set({memos: book.memos})
        set({readingSessions: book.readingSessions})
    }
}))

export default useBookDetailStore
