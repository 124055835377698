import {useEffect} from 'react'
import styled from 'styled-components';
import {useParams} from "react-router";
import RouteContainer from '../../../common/styles/RouteContainer';
import {Card} from "react-bootstrap";
import useBookIsbn from "./useBookIsbn";
import NoContent from "../../../common/NoContent";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import BookIsbnCard from "./BookIsbnCard";
import useUrlQuery from "../../../common/hooks/useUrlQuery";
import useLoginStore from "../../login/useLoginStore";
import searchCache from "../../search/searchBar/searchCache";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import ApiUrls from "../../../ApiUrls";
import IndexSearchBar from "../../index/card/IndexSearchBar";
import BookSourceOfflineLibrarySection from "../../search/bookSource/section/BookSourceOfflineLibrarySection";
import BookSourceOnlineLibrarySection from "../../search/bookSource/section/BookSourceOnlineLibrarySection";
import BookSourceSubscriptionSection from "../../search/bookSource/section/BookSourceSubscriptionSection";
import BookSourceOnlineUsedSection from "../../search/bookSource/section/BookSourceOnlineUsedSection";
import BookSourceOfflineUsedSection from "../../search/bookSource/section/BookSourceOfflineUsedSection";
import BookIsbnCardLoading from "./BookIsbnCardLoading";
import LoadingBar from "../../../common/LoadingBar";
import parse from "html-react-parser";
import useHtmlTitle from "../../../common/useHtmlTitle";

const BookIsbnRoute = () => {
    const {isbn} = useParams()
    const query = useUrlQuery('q')
    const [book, isLoading, isError] = useBookIsbn(isbn ?? null)

    useHtmlTitle(book?.title ?? '?')

    const isLoggedIn = useLoginStore((state) => state.isLoggedIn())
    useEffect(() => {
        if (!isLoggedIn) {
            searchCache.updateCache(
                'search-book-histories',
                query?.toString() ?? '',
                `/search/${isbn}`
            )
        } else if (isbn != null && query != null) {
            const body = {
                isbn: isbn,
                query: query
            }

            BooksitoutServer
                .post(ApiUrls.Search.BookHistory.POST, body)
                .then(r => {
                })
        }
    }, [isLoggedIn, isbn, query])

    if (isLoading) {
        return (
            <RouteContainer>
                <RowSpacer/>
                <IndexSearchBar/>

                <RowSpacer/>
                <BookIsbnCardLoading/>

                <RowSpacer/>
                <Card>
                    <CardBodyContentContainer height={400}>
                        <Container>
                            <Title>소개</Title>
                            <LoadingBar size={10}/>
                            <LoadingBar size={8}/>
                            <LoadingBar size={7}/>
                            <LoadingBar size={10}/>
                            <LoadingBar size={4}/>
                            <LoadingBar size={9}/>
                            <LoadingBar size={6}/>
                            <LoadingBar size={15}/>
                        </Container>
                    </CardBodyContentContainer>
                </Card>

                <RowSpacer/>
            </RouteContainer>
        )
    }

    if (book == null || isError || isbn == undefined) {
        return (
            <NoContent message={`찾으시는 책이 없어요<br/>(isbn = ${isbn})`}/>
        )
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <IndexSearchBar/>

            <RowSpacer/>
            <BookIsbnCard book={book}/>

            <RowSpacer/>
            <Card>
                <CardBodyContentContainer height={400}>
                    <Container>
                        <Title>소개</Title>
                        {parse(book.description?.replaceAll("\n", "<br/>") ?? '?')}
                    </Container>
                </CardBodyContentContainer>
            </Card>

            <RowSpacer/>
            <BookSourceOfflineLibrarySection isbn={isbn}/>

            <RowSpacer/>
            <BookSourceOnlineLibrarySection isbn={isbn}/>

            <RowSpacer/>
            <BookSourceSubscriptionSection isbn={isbn}/>

            <RowSpacer/>
            <BookSourceOnlineUsedSection isbn={isbn}/>

            <RowSpacer/>
            <BookSourceOfflineUsedSection isbn={isbn}/>

            <RowSpacer/>
        </RouteContainer>
    )
}

const Container = styled.div.attrs({
    className: 'row'
})`
    height: 100%;
    padding: 20px;
`

const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0;
    margin-bottom: 15px;
`

export default BookIsbnRoute